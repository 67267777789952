/*
 *
 *   Custom JS by Jonathan Iqueda
 *
 */

 // Generate a password string
function randString(size){
    var possible = 'abcdefghijklmnopqrstuvwxyz@123456789';

    var text = '';
    for(var i=0; i < size; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

$(document).ready(function () {
    const ERROR_AJAX = '<div class="alert alert-error" style="margin-top: 10px;">Ocorreu um erro interno, por favor entre em contato com o administrado do site.</div>';
    const LOADING = '<div class="loading-page"><div class="sk-circle"><div class="sk-circle1 sk-child"></div><div class="sk-circle2 sk-child"></div><div class="sk-circle3 sk-child"></div><div class="sk-circle4 sk-child"></div><div class="sk-circle5 sk-child"></div><div class="sk-circle6 sk-child"></div><div class="sk-circle7 sk-child"></div><div class="sk-circle8 sk-child"></div><div class="sk-circle9 sk-child"></div><div class="sk-circle10 sk-child"></div><div class="sk-circle11 sk-child"></div><div class="sk-circle12 sk-child"></div></div></div>';
    $.ajaxSetup({
        headers: {'X-CSRF-TOKEN': window.Laravel.csrfToken}
    });

    $('.btn-process-ajax-trigger').on('click', function (e) {
        $(this).prop("disabled", true);
        $('.general-ajax-with-redirect').trigger('submit');
    });

    $('.btn-finish-inquiry').on('click', function (e) {
        var form = $('.general-ajax-with-redirect');

        var d = new Date();

        var month = d.getMonth() + 1;
        var day = d.getDate();

        var output = d.getFullYear() + '-' +
            (month < 10 ? '0' : '') + month + '-' +
            (day < 10 ? '0' : '') + day;

        $('input[name="end_date"]').val(output);
        $('input[name="status"]').val('in_analysis');

        form.trigger('submit');
    });

    $('.general-ajax-with-redirect').on('submit', function (e) {
        "use strict";
        e.preventDefault();
        var self = this;
        var originalBtnTxt = $('.btn-process-ajax').text();
        var routeRedirect = $(this).data('back');

        $('.btn-finish-inquiry').prop("disabled", true);
        $(self).find('.btn-process-ajax').prop("disabled", true);
        
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: $(this).serializeArray(),
            timeout: 99999999,
            success: function (res) {
                if (res.status === 'success') {
                    var hasMsg = (res.response.msg != '' && res.response.msg != undefined);
                    var hasMessage = (res.message != '' && res.message != undefined);

                    if (hasMsg || hasMessage) {
                        var msg = (res.response.msg != '' && res.response.msg != undefined) ? res.response.msg : res.message;
                        swal({
                                title: msg,
                                type: "success",
                                closeOnConfirm: false
                            },
                            function (isConfirm) {
                                if (isConfirm) {
                                    window.location.href = ((routeRedirect != '' && routeRedirect != undefined) ? routeRedirect : res.response.route);
                                }
                            });

                        setTimeout(function () {
                            window.location.href = ((routeRedirect != '' && routeRedirect != undefined) ? routeRedirect : res.response.route);
                        }, 10000);
                    } else {
                        window.location.href = ((routeRedirect != '' && routeRedirect != undefined) ? routeRedirect : res.response.route);
                    }
                } else if (res.status === 'warning') {
                    if (res.response.hasOwnProperty('msg')) {
                        $(this).append('<div class="alert alert-warning" style="margin-top: 10px;">' + res.response.msg + '</div>');
                    } else {
                        $.each(res.response, function (k, v) {
                            var splitKey = k.split('.');

                            if (splitKey.length < 2) {
                                var input = $("input[name='" + k + "']");

                                if (input.length < 1) {
                                    var input = $("textarea[name='" + k + "']");
                                }

                                if (input.length < 1) {
                                    var input = $("select[name='" + k + "']");
                                }
                            } else {
                                var key = splitKey[0] + '[' + splitKey[1] + ']';

                                var input = $("input[name='" + key + "']");

                                if (input.length < 1) {
                                    var input = $("textarea[name='" + key + "']");
                                }

                                if (input.length < 1) {
                                    var input = $("select[name='" + key + "']");
                                }
                            }

                            if (input.length > 0) {
                                $.each(v, function (number, error) {
                                    input.parent().append('<div class="alert alert-warning" style="margin-top: 10px;">' + error + '</div>');
                                });
                            }
                        });

                        $('.btn-process-ajax-trigger').prop("disabled", false);
                        $(self).find('.btn-process-ajax').prop("disabled", false);
                        
                        $('#appendGlobalErrors').append('<div class="alert alert-warning" style="margin-top: 10px;">Por favor, confira os campos do formulário.</div>');
                    }
                } else {
                    $(this).append(ERROR_AJAX);
                }
            },
            fails: function (res) {
                swal({
                    title: 'Algo deu errado, por favor tente novamente.',
                    type: "error",
                    closeOnConfirm: false
                },
                function (isConfirm) {
                    if (isConfirm) {
                        window.location.href = ((routeRedirect != '' && routeRedirect != undefined) ? routeRedirect : res.response.route);
                    }
                });
            },
            beforeSend: function (res) {
                $('.alert').remove();
                $('body').prepend(LOADING);
            },
            complete: function (res) {
                $('.loading-page').remove();
                
                if ($("#formToClean").length > 0) {
                    $("#formToClean")[0].reset();
                }
            }
        });
    });

    $('.general-ajax-without-redirect').on('submit', function (e) {
        "use strict";
        e.preventDefault();
        var originalBtnTxt = $('.btn-process-ajax').text();

        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: $(this).serializeArray(),
            timeout: 99999999,
            success: function (res) {
                if (res.status === 'success') {
                    var msg = (res.response.msg != '' && res.response.msg != undefined) ? res.response.msg : res.message;
                    swal({
                        title: msg,
                        type: "success",
                        closeOnConfirm: true
                    });
                } else if (res.status === 'warning') {
                    if (res.response.hasOwnProperty('msg')) {
                        $(this).append('<div class="alert alert-warning" style="margin-top: 10px;">' + res.response.msg + '</div>');
                    } else {
                        $.each(res.response, function (k, v) {
                            var splitKey = k.split('.');

                            if (splitKey.length < 2) {
                                var input = $("input[name='" + k + "']");

                                if (input.length < 1) {
                                    var input = $("textarea[name='" + k + "']");
                                }

                                if (input.length < 1) {
                                    var input = $("select[name='" + k + "']");
                                }
                            } else {
                                var key = splitKey[0] + '[' + splitKey[1] + ']';

                                var input = $("input[name='" + key + "']");

                                if (input.length < 1) {
                                    var input = $("textarea[name='" + key + "']");
                                }

                                if (input.length < 1) {
                                    var input = $("select[name='" + key + "']");
                                }
                            }

                            if (input.length > 0) {
                                $.each(v, function (number, error) {
                                    input.parent().append('<div class="alert alert-warning" style="margin-top: 10px;">' + error + '</div>');
                                });
                            }
                        });

                        $('#appendGlobalErrors').append('<div class="alert alert-warning" style="margin-top: 10px;">Por favor, confira os campos do formulário.</div>');
                    }

                } else {
                    $(this).append(ERROR_AJAX);
                }
            },
            fails: function () {
            },
            beforeSend: function () {
                $('.alert').remove();
                $('body').prepend(LOADING);

            },
            complete: function () {
                $('.loading-page').remove();
            }
        });

    });

    $('.ajax-upload').on('submit', function (e) {
        "use strict";
        e.preventDefault();
        var originalBtnTxt = $('.btn-process-ajax').text();
        var routeRedirect = $(this).data('back');
        var formData = new FormData(this);

        $.ajax({
            url: $(this).attr('action'),
            type: 'POST',
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            xhr: function () {
                var myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload) { // Avalia se tem suporte a propriedade upload
                    myXhr.upload.addEventListener('progress', function () {
                        /* faz alguma coisa durante o progresso do upload */
                    }, false);
                }
                return myXhr;
            },
            success: function (res) {
                if (res.status === 'success') {
                    swal({
                            title: res.message,
                            type: "success",
                            closeOnConfirm: false
                        },
                        function (isConfirm) {
                            if (isConfirm) {
                                window.location.href = ((routeRedirect != '' && routeRedirect != undefined) ? routeRedirect : res.response.route);
                            }
                        });

                    setTimeout(function () {
                        window.location.href = routeRedirect;
                    }, 10000);
                } else if (res.status === 'warning') {
                    if (res.response.hasOwnProperty('msg')) {
                        $(this).append('<div class="alert alert-warning" style="margin-top: 10px;">' + res.response.msg + '</div>');
                    } else {
                        $.each(res.response, function (k, v) {
                            var splitKey = k.split('.');

                            if (splitKey.length < 2) {
                                var input = $("input[name='" + k + "']");

                                if (input.length < 1) {
                                    var input = $("textarea[name='" + k + "']");
                                }

                                if (input.length < 1) {
                                    var input = $("select[name='" + k + "']");
                                }
                            } else {
                                var key = splitKey[0] + '[' + splitKey[1] + ']';

                                var input = $("input[name='" + key + "']");

                                if (input.length < 1) {
                                    var input = $("textarea[name='" + key + "']");
                                }

                                if (input.length < 1) {
                                    var input = $("select[name='" + key + "']");
                                }
                            }

                            if (input.length > 0) {
                                $.each(v, function (number, error) {
                                    input.parent().append('<div class="alert alert-warning" style="margin-top: 10px;">' + error + '</div>');
                                });
                            }
                        });
                    }

                    $('#appendGlobalErrors').append('<div class="alert alert-warning" style="margin-top: 10px;">Por favor, confira os campos do formulário.</div>');
                } else {
                    $(this).append(ERROR_AJAX);
                }
            },
            beforeSend: function () {
                $('.alert').remove();
                $('body').prepend(LOADING);
            },
            complete: function () {
                $('.loading-page').remove();

                if ($("#formToClean").length > 0) {
                    $("#formToClean")[0].reset();
                }
            }
        });
    });

    $('.btn-delete-mode').on('click', function (e) {
        "use strict";
        e.preventDefault();
        var route = $(this).data('route');
        var formParams = $(this).data('route');

        swal({
                title: "Você tem certeza?",
                text: "Essa operação não é reversível!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Sim :(",
                cancelButtonText: "Não :)",
                closeOnConfirm: false,
                closeOnCancel: false
            },
            function (isConfirm) {
                if (isConfirm) {
                    $.ajax({
                        type: 'POST',
                        url: route,
                        data: formParams,
                        timeout: 99999999,
                        success: function (res) {
                            if (res.status === 'success') {
                                var hasMsg = (res.response.msg != '' && res.response.msg != undefined);
                                var hasMessage = (res.message != '' && res.message != undefined);

                                if (hasMsg || hasMessage) {
                                    var msg = (res.response.msg != '' && res.response.msg != undefined) ? res.response.msg : res.message;
                                    swal({
                                            title: msg,
                                            type: "success",
                                            closeOnConfirm: false
                                        },
                                        function (isConfirm) {
                                            if (isConfirm) {
                                                if (res.response.route != '' && res.response.route != undefined) {
                                                    window.location.href = res.response.route;
                                                } else {
                                                    location.reload();
                                                }
                                            }
                                        });

                                    setTimeout(function () {
                                        location.reload();
                                    }, 10000);
                                } else {
                                    location.reload();
                                }
                            } else if (res.status === 'warning') {
                                console.log(res.response);
                            } else {
                                console.log(res.response);
                            }
                        },
                        beforeSend: function () {
                            $('body').prepend(LOADING);
                        },
                        complete: function () {
                            $('.loading-page').remove();
                        }
                    });
                } else {
                    swal("Cancelado", "Sua operação foi cancelada com sucesso :)", "error");
                }
            });
    });

    $('.btn-approve-status-inquiry').on('click', function (e) {
        "use strict";
        e.preventDefault();
        
        $(this).prop("disabled", true);
        $.ajax({
            type: 'POST',
            url: $(this).data('route'),
            data: {status: 'finished'},
            timeout: 99999999,
            success: function (res) {
                if (res.status === 'success') {
                    var hasMsg = (res.response.msg != '' && res.response.msg != undefined);
                    var hasMessage = (res.message != '' && res.message != undefined);

                    if (hasMsg || hasMessage) {
                        var msg = (res.response.msg != '' && res.response.msg != undefined) ? res.response.msg : res.message;
                        swal({
                                title: msg,
                                type: "success",
                                closeOnConfirm: false
                            },
                            function (isConfirm) {
                                if (isConfirm) {
                                    location.reload();
                                }
                            });

                        setTimeout(function () {
                            location.reload();
                        }, 10000);
                    } else {
                        location.reload();
                    }
                } else if (res.status === 'warning') {
                    swal({
                        title: 'Houve um erro no servidor, por favor atualize sua pagina',
                        type: "warning",
                        closeOnConfirm: false
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            location.reload();
                        }
                    });
                } else {
                    swal({
                        title: 'Houve um erro no servidor, por favor atualize sua pagina',
                        type: "error",
                        closeOnConfirm: false
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            location.reload();
                        }
                    });
                }
            },
            beforeSend: function () {
                $('body').prepend(LOADING);
            },
            complete: function () {
                $('.loading-page').remove();
            }
        });
    });

    $('.btn-approve-mode').on('click', function (e) {
        "use strict";
        e.preventDefault();

        $.ajax({
            type: 'POST',
            url: $(this).data('route'),
            data: $(this).serializeArray(),
            timeout: 99999999,
            success: function (res) {
                if (res.status === 'success') {
                    var hasMsg = (res.response.msg != '' && res.response.msg != undefined);
                    var hasMessage = (res.message != '' && res.message != undefined);

                    if (hasMsg || hasMessage) {
                        var msg = (res.response.msg != '' && res.response.msg != undefined) ? res.response.msg : res.message;
                        swal({
                                title: msg,
                                type: "success",
                                closeOnConfirm: false
                            },
                            function (isConfirm) {
                                if (isConfirm) {
                                    location.reload();
                                }
                            });

                        setTimeout(function () {
                            location.reload();
                        }, 10000);
                    } else {
                        location.reload();
                    }
                } else if (res.status === 'warning') {
                    console.log(res.response);
                } else {
                    console.log(res.response);
                }
            },
            beforeSend: function () {
                $('body').prepend(LOADING);
            },
            complete: function () {
                $('.loading-page').remove();
            }
        });
    });

    function limpa_formulário_cep() {
        $("#rua").val("");
        $("#bairro").val("");
        $("#cidade").val("");
        $("#uf").val("");
    }

    //Quando o campo cep perde o foco.
    $("#cep").blur(function () {

        //Nova variável "cep" somente com dígitos.
        var cep = $(this).val().replace(/\D/g, '');

        //Verifica se campo cep possui valor informado.
        if (cep != "") {

            //Expressão regular para validar o CEP.
            var validacep = /^[0-9]{8}$/;

            //Valida o formato do CEP.
            if (validacep.test(cep)) {

                //Preenche os campos com "..." enquanto consulta webservice.
                $("#rua").val("Pesquisando ...");
                $("#bairro").val("Pesquisando ...");
                $("#cidade").val("Pesquisando ...");

                //Consulta o webservice viacep.com.br/
                $.getJSON("//viacep.com.br/ws/" + cep + "/json/?callback=?", function (dados) {

                    if (!("erro" in dados)) {
                        //Atualiza os campos com os valores da consulta.
                        $("#rua").val(dados.logradouro);
                        $("#bairro").val(dados.bairro);
                        $("#cidade").val(dados.localidade);

                        $("#uf > option").each(function () {
                            if (this.value == dados.uf) {
                                $('#uf').val(dados.uf).trigger('change');
                            }
                        });
                    } //end if.
                    else {
                        //CEP pesquisado não foi encontrado.
                        limpa_formulário_cep();
                        alert("CEP não encontrado.");
                    }
                });
            } //end if.
            else {
                //cep é inválido.
                limpa_formulário_cep();
                alert("Formato de CEP inválido.");
            }
        } //end if.
        else {
            //cep sem valor, limpa formulário.
            limpa_formulário_cep();
        }
    });

    $('#cep').mask('00000-000');
    $('input[name="cnpj"]').mask('00.000.000/0000-00', {reverse: false});
    $('input[name="cpf"]').mask('000.000.000-00', {reverse: false});
    $('input[name="phone"]').mask('(00) 0000-00000');
    $('#born_date').mask('00/00/0000');

    if ($('#lineChart').length > 0) {
        var lineData = {
            labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
            datasets: [
                {
                    label: "Consultas finalizadas",
                    backgroundColor: "rgba(26,179,148,0.5)",
                    borderColor: "rgba(26,179,148,0.7)",
                    pointBackgroundColor: "rgba(26,179,148,1)",
                    pointBorderColor: "#fff",
                    data: $('#inquiriesFinished').data('value')
                }
            ]
        };

        var lineOptions = {
            responsive: true
        };

        var ctx = document.getElementById("lineChart").getContext("2d");
        new Chart(ctx, {type: 'line', data: lineData, options: lineOptions});
    }

    if ($('.datatable').length > 0) {
        $('.datatable').DataTable({
            pageLength: 10,
            responsive: true,
            "bLengthChange": false,
            "bInfo": false,
            "oLanguage": {
                "sLoadingRecords": "Carregando...",
                "sProcessing": "Processando...",
                "sZeroRecords": "Nenhum registro encontrado",
                "sSearch": "Pesquisar:",
                "oPaginate": {
                    "sNext": "Próximo",
                    "sPrevious": "Anterior",
                    "sFirst": "Primeiro",
                    "sLast": "Último"
                }
            },
            "bSort": false,
            dom: '<"html5buttons"B>lTfgitp',
            buttons: [
                {extend: 'csv'},
                {extend: 'excel', title: 'exportar-tabela'},
                {extend: 'pdf', title: 'exportar-tabela'},

                {
                    extend: 'print',
                    customize: function (win) {
                        $(win.document.body).addClass('white-bg');
                        $(win.document.body).css('font-size', '10px');

                        $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
                    }
                }
            ]
        });

        $('.dataTables_filter').addClass('pull-left');
    }

    if ($('.select2_company').length > 0) {
        $(".select2_company").select2({
            placeholder: "Selecione a empresa",
            allowClear: true,
            language: {
                noResults: function () {
                    return "Nenhum Resultado Encontrado.";
                }
            }
        });
    }

    if ($('.select2_state').length > 0) {
        $(".select2_state").select2({
            placeholder: "Selecione a UF",
            allowClear: true,
            language: {
                noResults: function () {
                    return "Nenhum Resultado Encontrado.";
                }
            }
        });
    }

    if ($('.select2_user').length > 0) {
        $(".select2_user").select2({
            placeholder: "Selecione o tipo do usuário",
            allowClear: true,
            language: {
                noResults: function () {
                    return "Nenhum Resultado Encontrado.";
                }
            }
        });
    }

    if ($('.select2_filial').length > 0) {
        $(".select2_filial").select2({
            placeholder: "Selecione a filial",
            allowClear: true,
            language: {
                noResults: function () {
                    return "Nenhum Resultado Encontrado.";
                }
            }
        });
    }

    if ($('.select2_consult').length > 0) {
        $(".select2_consult").select2({
            placeholder: "Selecione o consultor",
            allowClear: true,
            language: {
                noResults: function () {
                    return "Nenhum Resultado Encontrado.";
                }
            }
        });
    }

    $('.select2_user').on('change', function (e) {
        e.preventDefault();
        var value = $(this).val();

        if (value == 'human_resource_analyst') {
            $('.showFiliais').show();
        } else {
            $('.showFiliais').hide();
        }
    });

    $('#data_1 .input-group.date').datepicker({
        todayBtn: "linked",
        keyboardNavigation: false,
        forceParse: false,
        calendarWeeks: true,
        format: 'dd/mm/yyyy',
        autoclose: true
    });

    $('#data_5 .input-daterange').datepicker({
        keyboardNavigation: false,
        forceParse: false,
        autoclose: true,
        format: 'dd/mm/yyyy'
    });

    $('.dual_select').bootstrapDualListbox({
        selectorMinimalHeight: 160,
        filterTextClear: 'Mostrar Todos',
        filterPlaceHolder: 'Filtrar',
        moveSelectedLabel: 'Mover Selecionado',
        moveAllLabel: 'Mover Todos',
        removeSelectedLabel: 'Remover Selecionado',
        removeAllLabel: 'Remover Todos',
        infoText: 'Total de Opções: {0}',
        infoTextFiltered: '<span class="label label-warning">Resultado:</span>  {0} de {1}',
        infoTextEmpty: 'Lista Vazia'
    });

    $('.btn-reload').on('click', function (e) {
        e.preventDefault();

        location.reload();
    });

    $("#checkebox-all").click(function(){
        if($(this).prop('checked')){
            $(".checkbox_data").prop('checked', true);
        }else{
            $(".checkbox_data").prop('checked', false);
        }
    });

    $('.btn-approve-mass').on('click', function (e) {
        "use strict";
        e.preventDefault();
        var arrayCheckbox = [];
        var consultId = null;
        $(".checkbox_data:checked").each(function(){
            arrayCheckbox.push($(this).val());
        });
        
        if($(".consult_id").length > 0){
            consultId = $(".consult_id").val();
        }

        $(this).prop("disabled", true);

        $.ajax({
            type: 'POST',
            url: $(this).data('route'),
            data: { consult_id: consultId, checkboxs: arrayCheckbox },
            timeout: 99999999,
            success: function (res) {
                if (res.status === 'success') {
                    var hasMsg = (res.response.msg != '' && res.response.msg != undefined);
                    var hasMessage = (res.message != '' && res.message != undefined);

                    if (hasMsg || hasMessage) {
                        var msg = (res.response.msg != '' && res.response.msg != undefined) ? res.response.msg : res.message;
                        swal({
                                title: msg,
                                type: "success",
                                closeOnConfirm: false
                            },
                            function (isConfirm) {
                                if (isConfirm) {
                                    location.reload();
                                }
                            });

                        setTimeout(function () {
                            location.reload();
                        }, 10000);
                    } else {
                        location.reload();
                    }
                } else if (res.status === 'warning') {
                    swal({
                        title: 'Houve um erro no servidor, por favor atualize sua pagina',
                        type: "warning",
                        closeOnConfirm: false
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            location.reload();
                        }
                    });
                } else {
                    swal({
                        title: 'Houve um erro no servidor, por favor atualize sua pagina',
                        type: "error",
                        closeOnConfirm: false
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            location.reload();
                        }
                    });
                }
            },
            beforeSend: function () {
                $('body').prepend(LOADING);
            },
            complete: function () {
                $('.loading-page').remove();
            }
        });
    });
    
    $(".btn-loading-return").click(function(e){
        $('body').prepend(LOADING);
    }); 

    $('.btn-fechamento').on('click', function (e) {
        "use strict";
        e.preventDefault();
        $(this).prop("disabled", true);

        $.ajax({
            type: 'GET',
            url: $(this).data('route'),
            data: { type: $(this).data('type') },
            timeout: 99999999,
            success: function (res) {
                if (res.status === 'success') {
                    console.log(res.data);
                    $("#hidden-template").tmpl(res.data).appendTo(".content-ajax");

                    swal({
                        title: "Fechamento gerado com sucesso!",
                        type: "success",
                        closeOnConfirm: true
                    });
                } else if (res.status === 'warning') {
                    swal({
                        title: 'Houve um erro no servidor, por favor atualize sua pagina',
                        type: "warning",
                        closeOnConfirm: false
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            location.reload();
                        }
                    });
                } else {
                    swal({
                        title: 'Houve um erro no servidor, por favor atualize sua pagina',
                        type: "error",
                        closeOnConfirm: false
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            location.reload();
                        }
                    });
                }
            },
            beforeSend: function () {
                $('body').prepend(LOADING);
            },
            complete: function () {
                $('.loading-page').remove();
            }
        });    
    });

    $('.btn-trigger-generic').on('click', function (e) {
        "use strict";
        e.preventDefault();
        var routeRedirect = $(this).data('back');

        $(this).prop("disabled", true);

        $.ajax({
            type: 'POST',
            url: $(this).data('route'),
            data: { status: $(this).data('status') },
            timeout: 99999999,
            success: function (res) {
                if (res.status === 'success') {
                    swal({
                        title: 'Erro reportado com sucesso!',
                        type: "success",
                        closeOnConfirm: false
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            window.location.href = ((routeRedirect != '' && routeRedirect != undefined) ? routeRedirect : res.response.route);
                        }
                    });
                } else if (res.status === 'warning') {
                    swal({
                        title: 'Houve um erro no servidor, por favor atualize sua pagina',
                        type: "warning",
                        closeOnConfirm: false
                    },
                        function (isConfirm) {
                            if (isConfirm) {
                                location.reload();
                            }
                        });
                } else {
                    swal({
                        title: 'Houve um erro no servidor, por favor atualize sua pagina',
                        type: "error",
                        closeOnConfirm: false
                    },
                        function (isConfirm) {
                            if (isConfirm) {
                                location.reload();
                            }
                        });
                }
            },
            beforeSend: function () {
                $('body').prepend(LOADING);
            },
            complete: function () {
                $('.loading-page').remove();
            }
        });
    });
    
    $(".btn-password").on("click", function(e){
        e.preventDefault();
        var pass = randString(6);
        $input = $(this).prev("[type='password']");
        $showPass = $(this).closest(".container-pass").find('.pass-copy');
        $input.val(pass);
        $showPass.val(pass);

        $(this).closest(".container-pass").find('.show-pass').show();
    });

    $(".btn-copy").on("click", function(e){
        $input = $(this).prev(".pass-copy");
        $(this).prev(".pass-copy").select();
        document.execCommand("copy");

        $('<p class="alert-copy">Senha copiada!</p>').insertBefore($input);
        setTimeout(function(){ $('.alert-copy').hide().remove(); }, 4000);
    });
});